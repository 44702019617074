import { Link } from 'gatsby';
import React from 'react';

interface ConvenienceStoreNetworkSectionProps {
  title: string;
  body: string;
  cta: {
    label: string;
    url: string;
  };
  customers: string;
  transactionsCapturedPerDay: string;
  trackedSalesPerDay: string;
}

const ConvenienceStoreNetworkSection: React.FC<ConvenienceStoreNetworkSectionProps> = ({
  title,
  body,
  cta,
  customers,
  transactionsCapturedPerDay,
  trackedSalesPerDay,
}) => {
  return (
    <div className="container max-w-screen-xl mx-auto my-20 md:my-32 text-center">
      <div className="px-6 md:px-20 xl:px-44">
        <h2 className="u-h2 mb-4">{title}</h2>
        <p className="mb-5 text-lg lg:text-xl px-6 md:px-20 xl:px-44">{body}</p>
        <Link className="u-cta-link" to={cta.url}>
          {cta.label}
        </Link>
      </div>

      <div
        className="mt-20 bg-center bg-cover md:bg-contain bg-no-repeat py-10 space-y-12 md:space-y-16"
        style={{ backgroundImage: 'url(/images/network-map-bg.svg)' }}
      >
        <div>
          <div className="text-5xl lg:text-7.5 font-semibold text-violet-alt">
            {customers}
          </div>
          <div className="text-xl lg:text-3.5xl font-semibold">Customers</div>
        </div>
        <div>
          <div className="text-5xl lg:text-7.5 font-semibold text-aqua">
            {transactionsCapturedPerDay}
          </div>
          <div className="text-xl lg:text-3.5xl font-semibold">
            Transactions Captured Per Day
          </div>
        </div>
        <div>
          <div className="text-5xl lg:text-7.5 font-semibold text-primary">
            {trackedSalesPerDay}
          </div>
          <div className="text-xl lg:text-3.5xl font-semibold">
            Tracked Sales Per Day
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConvenienceStoreNetworkSection;
