import { Link } from 'gatsby';
import React from 'react';

interface ConnectingBrandsSectionProps {
  title: string;
  quote: string;
  author: string;
}

const ConnectingBrandsSection: React.FC<ConnectingBrandsSectionProps> = ({
  title,
  quote,
  author,
}) => {
  return (
    <div className="my-20 md:my-32 overflow-hidden">
      <div className="md:flex justify-end relative">
        <img
          src="/images/brands-use.jpg"
          alt=""
          className="w-full md:w-6/12 lg:w-7/12 h-full"
        />
        <div className="md:absolute top-0 right-0 bottom-0 left-0 px-6 lg:px-md pt-12 md:pt-0">
          <div className="u-container h-full flex items-center text-center md:text-left">
            <div className="w-full md:w-6/12 lg:w-5/12">
              <h2 className="u-h2 mb-5 lg:w-120">{title}</h2>
              {/*
              <Link to="/" className="inline-block u-cta-link mb-12 lg:mb-36">
                Explore customer stories
              </Link>*/}

              <p className="mt-12 md:mt-36 mb-4 md:mb-4 text-xl lg:text-2xl font-semibold tracking-tight w-89 max-w-full mx-auto md:mx-0">
                {quote}
              </p>
              <div className="text-paragraph max-w-sm mx-auto md:ml-0">{author}</div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="container max-w-screen-xl mx-auto mb-16 px-6 lg:px-md xl:px-0 text-center lg:text-left">
        <h2 className="u-h2 mb-5 lg:w-120">
          Over 14,000 retailer &amp; brands use Skupos
        </h2>
        <Link to="/" className="u-cta-link">
          Explore customer stories
        </Link>
      </div>
      <div className="flex flex-col lg:flex-row mx-auto lg:mx-0 w-120 max-w-full lg:w-full">
        <div className="flex-1 h-32">
          <img
            className="w-full h-auto"
            src="/images/connecting-brands-1.png"
            alt=""
          />
        </div>
        <div className="flex-1 px-16 py-16 lg:py-0">
          <div className="h-full flex items-center">
            <div>
              <div className="mb-12">
                <img
                  className="h-8 w-auto"
                  src="/images/coca-cola-logo.svg"
                  alt="Coca-Cola"
                />
              </div>

              <p className="mb-10 text-2xl lg:text-4xl font-semibold tracking-tight">
                “Pellentesque aliquam tempor quis mi. Erat commodo ut facilisis
                vehicula viverra”
              </p>
              <div className="text-paragraph">John Brown, Head of Retail</div>
            </div>
          </div>
        </div>
        <div className="flex-1">
          <img
            className="w-full h-auto"
            src="/images/connecting-brands-2.png"
            alt=""
          />
        </div>
      </div> */}
    </div>
  );
};

export default ConnectingBrandsSection;
