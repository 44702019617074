import { Link } from 'gatsby';
import { string } from 'prop-types';
import React from 'react';
import DarkBenefitCard from 'components/molecules/DarkBenefitCard';
import { html2react } from 'helpers/parser';

interface HomepageConvenienceRetailSectionProps {
  title?: string;
  cta?: {
    label: string;
    url: string;
  };
  benefits?: {
    lead: string;
    title: string;
    body: string;
    image: string;
    cta: {
      label: string;
      url: string;
    };
  }[];
}

const HomepageConvenienceRetailSection: React.FC<HomepageConvenienceRetailSectionProps> = ({
  title,
  cta,
  benefits,
}) => {
  return (
    <div className="bg-tile my-20 md:my-32">
      <div className="container max-w-screen-xl mx-auto py-16 lg:py-32">
        <div className="px-6 md:px-20 xl:px-44 text-center mb-16 lg:mb-32">
          <h2 className="u-h2 mb-8 w-150 max-w-full mx-auto">
            {html2react(title)}
          </h2>
          <Link to={cta.url} className="u-cta-link">
            {cta.label}
          </Link>
        </div>

        <div className="space-y-20 lg:mx-16">
          {benefits.map((benefit, index) => (
            <DarkBenefitCard
              key={index}
              lead={benefit?.lead}
              title={benefit?.title}
              body={benefit?.body}
              cta={benefit?.cta}
              imageUrl={benefit?.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomepageConvenienceRetailSection;
