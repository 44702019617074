import { Link } from 'gatsby';
import React from 'react';

interface DarkBenefitCardProps {
  lead: string;
  title: string;
  body: string;
  cta: { url: string; label: string };
  imageUrl: string;
}

const DarkBenefitCard: React.FC<DarkBenefitCardProps> = ({
  lead,
  title,
  body,
  cta,
  imageUrl,
}) => {
  return (
    <div className="md:bg-dark-grey md:text-white flex-col-reverse lg:flex-row flex items-center px-6 md:px-0 md:mx-20 lg:mx-0">
      <div className="flex-grow md:px-16 pt-12 lg:py-0 text-center md:text-left">
        <div className="font-semibold opacity-50 md:opacity-80 mb-2.5">
          {lead}
        </div>
        <h3 className="font-bold u-h3 leading-tight lg:mr-28 mb-3.5">
          {title}
        </h3>
        <p className="opacity-60 text-lg mb-6">{body}</p>
        <Link className="u-cta-link md:u-cta-link--white" to={cta.url}>
          {cta.label}
        </Link>
      </div>
      <img
        className="w-full lg:w-auto h-auto lg:h-96"
        src={imageUrl}
        alt={title}
      />
    </div>
  );
};

export default DarkBenefitCard;
