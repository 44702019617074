import { Link } from 'gatsby';
import React from 'react';
import { html2react } from 'helpers/parser';

interface BenefitProps {
  lead?: string;
  leadColor?: 'text-aqua' | 'text-violet' | 'text-orange';
  title: string;
  body?: string;
  cta?: { label: string; to: string };
  image: string;
  imageSide?: 'left' | 'right';
  centered?: boolean;
  margin?: boolean;
}

const Benefit: React.FC<BenefitProps> = ({
  lead,
  leadColor = 'text-aqua',
  title,
  body,
  cta,
  image,
  imageSide = 'right',
  centered,
  margin = true,
  children,
}) => {

  const direction =
    imageSide === 'right' ? 'lg:flex-row' : 'lg:flex-row-reverse';
  const bodyMargin =
    imageSide === 'right' ? 'mx-6 xl:mr-32 xl:ml-8' : 'mx-6 xl:ml-32 lg:pr-8 xl:mr-8';
  const bodyHtml = body ? body.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';

  return (
    <div
      className={`flex flex-col-reverse items-center ${
        margin ? 'mb-16 lg:mb-24 last:mb-0' : ''
      } ${direction} ${centered ? 'text-center' : ''}`}
    >
      <div className="pt-8 md:py-8 w-full lg:w-6/12 text-center md:text-left">
        <div className={bodyMargin}>
          {lead && (
            <div
              className={`mb-2.5 mt-4 lg:mt-0 font-semibold text-lg leading-relaxed tracking-tighter ${leadColor}`}
            >
              {lead}
            </div>
          )}
          <h2 className="pt-2 lg:pt-0 mb-4 u-h2 text-dark-grey">
            {title}
          </h2>
          {body && (
            <p className="text-lg lg:text-xl text-dark-grey">{html2react(bodyHtml)}</p>
          )}
          {cta && (
            <div className="mt-6">
              <Link
                className="u-cta-link"
                to={cta.to || cta.url}
              >
                {cta.label}
              </Link>
            </div>
          )}
          {children}
        </div>
      </div>
      <div className="w-full px-6 md:mx-0 lg:w-6/12">
        <img className="w-full h-auto" src={image} alt={title} />
      </div>
    </div>
  );
};

export default Benefit;
