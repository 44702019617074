import { Link } from 'gatsby';
import React from 'react';

interface Cta {
  label: string;
  url: string;
}

interface HomepageHeroSectionProps {
  title: string;
  description: string;
  primaryCta: Cta;
  secondaryCta: Cta;
  footerHeading: string;
  footerLogos: string[];
  imageData?: {
    src: string;
    srcSet: string;
  };
  image?: string;
}

const HomepageHeroSection: React.FC<HomepageHeroSectionProps> = ({
  title,
  description,
  primaryCta,
  secondaryCta,
  footerHeading,
  footerLogos,
  imageData,
  image = '',
}) => {
  return (
    <div
      className="home-hero pt-16 md:pt-xl overflow-hidden"
      style={{ backgroundColor: '#FAFAFA' }}
    >
      <div className="container mx-auto flex max-w-screen-xl px-6 md:px-md">
        <div className="md:w-110 py-16 md:py-20 lg:py-28 relative">
          <h1 className="u-h1 mb-5 max-w-lg">{title}</h1>
          <p className="text-xl mb-8 md:mb-10 max-w-lg">{description}</p>

          <div className="md:flex space-y-6 md:space-y-0 md:space-x-6 mb-12 md:mb-16">
            <Link className="u-btn u-btn--green" to={primaryCta.url}>
              {primaryCta.label}
            </Link>
            <Link className="u-btn u-btn--outline" to={secondaryCta.url}>
              {secondaryCta.label}
            </Link>
          </div>
          <div className="mb-7 text-dark-grey opacity-40 font-semibold text-sm">
            {footerHeading}
          </div>

          <div className="flex items-center space-x-12">
            {footerLogos.map((logoUrl, index) => (
              <img
                src={logoUrl}
                alt="Customer"
                key={index}
                className="w-auto h-8 mb-12 md:mb-0"
              />
            ))}
          </div>

          <div
            className="home-hero__image md:absolute inset-y-0 w-full md:h-full xl:ml-8"
            style={{ left: '100%' }}
          >
            <img
              src={imageData && imageData.src ? imageData.src : image}
              srcSet={imageData && imageData.srcSet ? imageData.srcSet : ''}
              className="md:h-full object-contain mx-auto md:max-w-2xl lg:max-w-3xl xl:max-w-4xl"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomepageHeroSection;
