import { graphql } from 'gatsby';
import 'isomorphic-fetch';
import React from 'react';

import Layout from 'components/base/Layout';
import SEO from 'components/base/SEO';
import Benefit from 'components/molecules/Benefit';
import BenefitsSection from 'components/organisms/BenefitsSection';
import ConnectingBrandsSection from 'components/organisms/ConnectingBrandsSection';
import ConvenienceStoreNetworkSection from 'components/organisms/ConvenienceStoreNetworkSection';
import CtaSection from 'components/organisms/CtaSection';
import HomepageConvenienceRetailSection from 'components/organisms/HomepageConvenienceRetailSection';
import HomepageHeroSection from 'components/organisms/HomepageHeroSection';
import { html2react } from 'helpers/parser';
import getImageData from 'helpers/nf-image-data';

const heroImageData = getImageData('/images/fluid/homepage-hero.jpg');

const IndexPageContent = ({
  hero,
  benefitsSection,
  convenienceStoreNetwork,
  convenienceRetail,
  connectingBrandsSection,
  tileGridTitle,
  tileGridQuote,
  tileGridQuoteAuthor,
  cta
}) => (
    <>
      <HomepageHeroSection
        imageData={heroImageData}
        title={hero?.title}
        description={hero?.description}
        primaryCta={hero?.primaryCta}
        secondaryCta={hero?.secondaryCta}
        footerHeading={hero?.footerHeading}
        footerLogos={hero?.footerLogos.map((logo) => logo.image)}
      />
      <BenefitsSection>
        <h2 className="mb-8 lg:mb-16 px-10 u-h2">{html2react(benefitsSection?.title)}</h2>
        {benefitsSection.benefits.map((benefit) => (
          <Benefit
            key={benefit?.title}
            title={benefit?.title}
            body={benefit?.body}
            image={benefit?.image}
            imageSide={benefit?.imageSide}
            cta={benefit?.cta}
          />
        ))}
      </BenefitsSection>

      <ConvenienceStoreNetworkSection
        title={convenienceStoreNetwork?.title}
        body={convenienceStoreNetwork?.body}
        cta={convenienceStoreNetwork?.cta}
        customers={convenienceStoreNetwork?.customers}
        transactionsCapturedPerDay={
          convenienceStoreNetwork?.transactionsCapturedPerDay
        }
        trackedSalesPerDay={
          convenienceStoreNetwork?.trackedSalesPerDay
        }
      />
      <HomepageConvenienceRetailSection
        title={convenienceRetail?.title}
        cta={convenienceRetail?.cta}
        benefits={convenienceRetail?.benefits}
      />
      <BenefitsSection
        title={connectingBrandsSection?.title}
        body={connectingBrandsSection?.body}
        cta={{
          to: connectingBrandsSection?.cta?.url,
          label: connectingBrandsSection?.cta?.label,
        }}
        constrainTitle
      >
        {connectingBrandsSection.benefits.map((benefit, index) => (
          <Benefit
            key={index}
            title={benefit?.title}
            body={benefit?.body}
            image={benefit?.image}
            imageSide={benefit?.imageSide}
            cta={{ to: benefit?.cta.url, label: benefit?.cta.label }}
          />
        ))}
      </BenefitsSection>
      <ConnectingBrandsSection
        title={tileGridTitle}
        quote={tileGridQuote}
        author={tileGridQuoteAuthor}
      />
      {/*<HomepageMasonrySection/>*/}
      <CtaSection headline={cta?.headline} />
    </>
)

export const IndexPreview = ({ entry }) => {
  return <IndexPageContent {...entry.get('data').toJS()} />;
};

const IndexPage = ({
   data: {
     markdownRemark: { frontmatter },
   },
 }) => {
  const { seo } = frontmatter;

  return (
    <Layout>
      <SEO
        title={seo?.title}
        description={seo?.description}
        openGraphImage={seo?.openGraphImage}
      />
      <IndexPageContent {...frontmatter } />
    </Layout>
  );
};

export const pageQuery = graphql`
  query HomepageQuery {
    markdownRemark(fileAbsolutePath: { regex: "/pages/index/" }) {
      frontmatter {
        seo {
          title
          description
          openGraphImage
        }
        hero {
          title
          description
          primaryCta {
            label
            url
          }
          secondaryCta {
            label
            url
          }
          footerHeading
          footerLogos {
            image
          }
        }
        benefitsSection {
          title
          benefits {
            title
            body
            image
            imageSide
            cta {
              label
              url
            }
          }
        }
        convenienceStoreNetwork {
          title
          body
          cta {
            label
            url
          }
          customers
          transactionsCapturedPerDay
          trackedSalesPerDay
        }
        convenienceRetail {
          title
          cta {
            label
            url
          }
          benefits {
            lead
            title
            body
            image
            cta {
              label
              url
            }
          }
        }
        connectingBrandsSection {
          title
          body
          cta {
            label
            url
          }
          benefits {
            title
            body
            image
            imageSide
            cta {
              label
              url
            }
          }
        }
        tileGridTitle
        tileGridQuote
        tileGridQuoteAuthor
        cta {
          headline
        }
      }
    }
  }
`;

export default IndexPage;
